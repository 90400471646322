.footer {
    background-color: #000000;
    padding: 25px;
}
.footer h3 {
    color: #ffffff;
    font-size: 18px;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.footer .contactUs h5 {
    color: #ffffff;
    font-size: 14px;
    line-height: 15px;
    font-family: "Raleway", sans-serif;
    font-weight: normal;
}

.footer .follow .footerIcon {
    color: #ffffff;
    background-color: #4c4c4c;
    height: 40px;
    width: 50px;
    border-radius: 5px;
    /* font-size: 24px; */
    margin-right: 10px;
    cursor: pointer;
}
.lastDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: black;
}
.lastDiv span {
    font-size: 14px;
    color: white;
    font-family: "Raleway", sans-serif;
    font-weight: normal;
    opacity: 1;
}
.subscribe form {
    display: flex;
    align-items: center;
}
.subscribe input[type="email"] {
    border: none;
    outline: none;
    background-color: #4c4c4c;
    border-color: #4c4c4c;
    color: #ffffff;
    height: 30px;
    padding: 0 25px;
    border-radius: 4px 0 0 4px;
    width: 230px;
    height: 40px;
    font-size: 12px;
    font-family: "Raleway", sans-serif;
}
.subscribe input[type="email"]::placeholder {
    color: #ffffff;
}
.subscribe input[type="submit"] {
    border-radius: 0 4px 4px 0;
    width: 94px;
    height: 40px;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: #a50e24;
    color: #ffffff;
    font-size: 12px;
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    font-weight: bold;
}

@media only screen and (max-width: 770px) {
    .footer .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .footer h3 {
        margin-bottom: 10px;
    }
    .footer .container .follow {
        margin-bottom: 10px;
    }
}
