/* Header Logo */
.fixed-top {
    background-color: #000;
    transition: 0.5s ease;
}
.fixed-top.active {
    background-color: black;
    transition: 0.5s ease;
}

.headerLogo {
    display: flex;
    align-items: center;
}
.headerLogo .leftProp,
.rightProp {
    color: #ffffff;
    text-decoration: none;
    font-size: 27px;
    font-family: "Raleway", sans-serif;
}
.headerLogo .leftProp {
    font-weight: bold;
}
.headerLogo .rightProp {
    font-weight: lighter;
}
.headerLogo:hover {
    text-decoration: none;
}
.headerIcon {
    stroke: #ffffff;
    height: 40px;
}

/* Header nav item */
.navbar .navbar-nav .nav-item {
    margin-left: 35px;
}

.nav-item .main-nav {
    color: #ffffff;
    text-decoration: none;
    font-family: "Raleway", sans-serif;
    font-size: 18px;
}
.nav-item .main-nav-active {
    background-color: #a50e24;
    border-radius: 15px;
    padding: 5px 10px;
    transition: 0.2s ease;
}

@media only screen and (max-width: 600px) {
    .fixed-top {
        background-color: black;
    }
}


