.secondContent {
    background-image: url("../../Assets/Images/HomeBg.png");
    background-position: 0px 0px;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    overflow: hidden;
}
.secondBar .contentTo {
    margin-top: 220px;

    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}
.secondBar .contentTo h1 {
    color: #fff;
    font-size: 36px;
    font-weight: 500;
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    text-align: center;
}
.contentTo h1 {
    text-align: center;
}
.secondCover p {
    color: #fff;
    /* margin-bottom: 70px; */
    text-align: left;
}

.newsDetailDiscription {
    color: #fff;
    margin-bottom: 70px;
    text-align: left;
}

.leftBar .contentTo select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: #fff;
    font-size: 20px;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    text-transform: capitalize;
    box-shadow: 0px 3px 10px #00000012;
    border-radius: 10px;
    background-color: #ffffff;
    border-color: #e4e3e3;
    background-position: right 10px top 50%;
    padding: 10px 30px 10px 10px;
    background: url("../../Assets/Images/down-chevron.png") no-repeat;
}
.secondTitle {
    text-align: center;
    margin: 70px 0px 80px;
}

@media only screen and (max-width: 990px) {
    .detailImg {
        max-width: 100%;
        height: auto;
    }
}

.dropbox-images {
    height: 100% !important;
    width: 100% !important;
}


@media (max-width: 990px) {
    .iframer {
        width: 1000px !important;
        height: 500px !important;
        transform: scale(0.5);
        margin-left: -100px !important;
    }
}

.nameRow {
    list-style-type: none;
    display: flex;
    padding: 0;
}
.nameRow:active {
    border: none;
}
.discriptionRow {
    list-style-type: none;
    padding: 0;
}
.icoli {
    margin-right: 15px;
}
#textDesc {
    border-radius: 4px;
    margin-left: 56px;
}
#userIcon {
    border-radius: 4px;
}
#userRow {
    border-radius: 4px;
    padding: 7px 25px;
    border: 1px solid #fff;
}
#userRow:focus {
    border: 1px solid red;
}
#comRow {
    width: 444px;
    border-radius: 4px;
    padding: 6px 5px 0px 5px;
    border: 1px solid #fff;
    background: #fff;
}

.comRow {
    /* width: 444px; */
    border-radius: 4px;
    padding: 6px 5px 0px 5px;
    border: 1px solid #fff;
    background: #fff;
}

.commentBtn {
    text-transform: capitalize;
    color: #a50e24;
    background-color: #ffffff;
    border-radius: 30px;
    padding: 8px 30px;
    margin-left: 56px;
    margin-top: 9px;
    font-weight: 800;
    font-family: "Raleway", sans-serif;
    border: none;
    font-size: 16px;
    position: relative;
}
.commentRow {
    display: flex;
}
.nameComm {
    text-align: center;
    color: red;
    margin-right: 10px;
}
#iconRow {
    margin-left: 41px;
    margin-bottom: 15px;
}
#mainRow {
    margin: 0;
    padding: 0;
}
#commR {
    background-color: #fff;
    border-radius: 4px;
}
.commentP {
    margin: 0;
    padding: 0;
}

