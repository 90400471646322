.adv-blockdownload {
	background: #a50e24 0% 0% no-repeat padding-box;
	box-shadow: 0px 1px 4px #00000029;
	border-radius: 10px;
}

.adv-blockdownload-wrapper {
	background: transparent url("../../Assets/Images/adv-download-bg.png") 0% 0%
		no-repeat padding-box;
	background-size: cover;
	padding: 263px 0 89px 0;
	position: relative;
}

.adv-blockdownload-logowrapper {
	position: absolute;
	top: 0;
	left: 0;
	background: #a50e24 0% 0% no-repeat padding-box;
	color: #fff;
}

.adv-blockdownload-logowrapper::before {
	content: "";
}

.adv-blockdownload-logowrapper::after {
	content: "";
}

.adv-blockdownload-logowrapper .adv-blockdownload-logo-content {
	padding: 23px 18px 41px 34px;
	text-align: center;
}

.adv-blockdownload-logowrapper .adv-blockdownload-logo-content h3 {
	font: normal normal bold 40px/42px Raleway;
	margin: 0;
}

.adv-blockdownload-logowrapper .adv-blockdownload-logo-content p {
	font: italic normal 600 14px Raleway;
	margin: 0;
}

.adv-blockdownload-contentwrapper {
	text-align: center;
}

.adv-blockdownload-contentwrapper .adv-download-btn {
	color: #fff;
	background: #a50e24 0% 0% no-repeat padding-box;
	border-radius: 27px;
	padding: 15px 34px;

	font: normal normal normal 18px Raleway;
	box-shadow: none !important;
	outline: none !important;
}
