/* News main content */
.newsContent {
    margin-top: 80px;
    margin-bottom: 80px;
}

.newsContent .col-xl-4 {
    padding-left: 0px;
}

/* News Bars */
.newsContent .rightNewsBar {
    background: #ffffff;
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 10px;
}

/* left News Bar */

.leftNewsBar .headerBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}
.leftNewsBar .headerBar h1 {
    color: #000000;
    font-size: 36px;
    font-weight: 900;
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
}
.dropbox-images {
    height: 100% !important;
    width: 100% !important;
}

.leftNewsBar .headerBar select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: #000000;
    font-size: 20px;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    text-transform: capitalize;
    box-shadow: 0px 3px 10px #00000012;
    border-radius: 10px;
    background-color: #ffffff;
    border-color: #e4e3e3;
    background: url("../../Assets/Images/down-chevron.png") no-repeat;
    background-position: right 10px top 50%;
    padding: 10px 30px 10px 10px;
}
.leftNewsBar select option {
    padding: 10px 30px 10px 10px;
}

.leftNewsBar .newsCover {
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 10px 10px 0px 0px;
}
.leftNewsBar .newsCover .newsDiscription {
    padding: 30px;
}
.leftNewsBar .newsCover h1 {
    color: #a50e24;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 22px;
    text-transform: capitalize;
}
.leftNewsBar .newsCover p {
    line-height: 35px;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
}
.leftNewsBar .newsCover .footerBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
}
.leftNewsBar .newsCover .footerBar button {
    text-transform: capitalize;
    color: #a50e24;
    background-color: rgba(255, 255, 255, 1);
    border: none;
    border-radius: 30px;
    padding: 10px 55px;
    padding-right: 70px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    font-weight: 800;
    font-family: "Raleway", sans-serif;
    border-color: #cccccc;
    font-size: 18px;
    position: relative;
}
.leftNewsBar .newsCover .footerBar button .moreBtn {
    position: absolute;
    right: -1%;
    top: 0%;
    background-color: #a50e24;
    color: #ffffff;
    font-size: 10px;
    padding: 13px;
    border-radius: 100%;
    height: 48px;
    fill: #ffffff;
}
.leftNewsBar .newsCover .footerBar .newsDate {
    color: #a50e24;
    font-size: 14px;
    font-weight: 600;
}

/* Right News Bar  */

.rightNewsBar .tab-link {
    width: 100%;
}

.newsInline .newsTabs .tab-link {
    color: #000;
    text-transform: capitalize;
    font-size: 18px;
    box-shadow: 1px 1px 1px #d0d4e159;
    font-family: "Raleway", sans-serif;
    margin: 0px;
    font-weight: 600;
    padding: 15px;
    transition: 0s;
}

.newsInline .newsTabs .fEHnjU {
    background-color: #ffffff;
    color: #a50e24;
    border-color: #a50e24;
}
.newsInline .kZvMDR {
    margin-bottom: 0px;
}

.newsInline .newsItem {
    display: flex;
    padding: 15px;
    box-shadow: 0px 1px 4px #00000029;
}

.newsInline .newsDecrip {
    width: 100%;
}

.newsItem .newsDecrip h6 {
    font-size: 12px;
    color: #000;
    text-transform: capitalize;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    padding: 5px;
}
.newsDecrip .moreDiscrip {
    display: flex;
    justify-content: space-between;
    padding: 5px;
}
.newsDecrip .moreDiscrip span,
.newsDecrip .moreDiscrip a {
    color: #a50e24;
    font-size: 10px;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    cursor: pointer;
}

/* Pagination */

.paginationBttns {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline-start: 0px !important;
    height: 40px;
    margin-top: 15px;
}
.paginationBttns a {
    padding: 5px 8px;
    margin: 2px;
    border-radius: 10px;
    border: 2px solid #000;
    color: #000;
    cursor: pointer;
}
.paginationBttns a:hover {
    color: #fff !important;
    background-color: #a50e24;
    text-decoration: none !important;
    border-color: #a50e24;
    /* transition: 0.1s ease-in-out; */
}
.paginationAcitive a {
    color: #fff !important;
    background-color: #a50e24;
    border-color: #a50e24;
    outline: none;
}
@media only screen and (max-width: 990px) {
    .leftNewsBar {
        margin-bottom: 25px;
    }
}
@media only screen and (max-width: 600px) {
    .newsContent {
        margin-top: 25px;
    }

    .newsCover img {
        height: 230px;
        width: 100%;
    }
    .leftNewsBar {
        box-shadow: 0 0 50px #ccc;
        border-radius: 30px 30px 0px 0px;
    }
    .rightNewsBar .cvjUth {
        box-shadow: 0 0 50px #ccc;
    }

    .leftNewsBar .headerBar h1 {
        padding: 15px 15px;
        font-size: 30px;
    }
    .leftNewsBar .headerBar select {
        padding: 0px 30px 5px 10px;
    }
    .leftNewsBar .newsCover .footerBar {
        flex-direction: column-reverse;
        padding: 0px;
        padding-bottom: 20px;
    }
    .leftNewsBar .newsCover .footerBar button {
        padding: 5px 25px;
        padding-right: 50px;
        margin-bottom: 10px;
    }
    .leftNewsBar .newsCover .footerBar button .moreBtn {
        padding: 8px;
        height: 37px;
    }

    .newsContent .container .row .col-xl-4 {
        padding: 0px;
        margin-top: 15px;
    }
}
