.horizontal-adv {
	font-family: "Roboto", sans-serif;
	background: transparent url("../../Assets/Images/horizontal-adv-bg.svg") 0%
		0% no-repeat padding-box;
	padding: 30px 0;
	margin-bottom: 50px;
	position: relative;
}

.horizontal-adv-layer {
	background: transparent
		linear-gradient(127deg, #000000 0%, #5b5b5b 73%, #7d7d7d 100%) 0% 0%
		no-repeat padding-box;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.6;
}

.horizontal-adv .adv-subtitle {
	font: normal normal normal 49px/59px Roboto;
	letter-spacing: 0px;
	text-transform: uppercase;
	opacity: 1;
}

.horizontal-adv .adv-title {
	font: normal normal bold 70px/85px Roboto;
	letter-spacing: 0px;
	text-transform: uppercase;
	opacity: 1;
}

.horizontal-adv .adv-sale {
	font: normal normal normal 90px/95px Roboto;
	letter-spacing: 0px;
	text-transform: uppercase;
	opacity: 1;
}

.horizontal-adv .adv-btn {
	color: #fff;
	background: #a50e24 0% 0% no-repeat padding-box;
	padding: 15px 52px;
	border-radius: 40px;
	font: normal normal normal 34px Raleway;
	box-shadow: none !important;
	outline: none !important;
}

.text-firebrick {
	color: #a50e24;
}
