.adv-blocksubscribe {
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 1px 4px #00000029;
	border-radius: 10px;
	padding: 8px 7px;
}

.adv-blocksubscribe-wrapper {
	background: transparent url("../../Assets/Images/adv-subscribe-bg.png") 0%
		0% no-repeat padding-box;
	background-size: cover;
	padding: 28px 0 38px 0;
}

.adv-blocksubscribe-logowrapper {
	margin-bottom: 137px;
}

.adv-blocksubscribe-logowrapper .adv-logo {
	font-size: 27px;
	font-family: "Raleway", sans-serif;
	text-align: center;
}

.adv-blocksubscribe-contentwrapper {
	color: #ffffff;
	text-align: center;
}

.adv-blocksubscribe-contentwrapper .adv-blocksubscribe-content {
	font: normal normal bold 32px/42px Raleway;
	letter-spacing: 0px;
	color: #ffffff;
	text-transform: uppercase;
}

.adv-blocksubscribe-contentwrapper .adv-subscribe-btn {
	color: #fff;
	background: #a50e24 0% 0% no-repeat padding-box;
	border-radius: 27px;
	padding: 15px 34px;

	font: normal normal normal 18px Raleway;
	box-shadow: none !important;
	outline: none !important;
}
