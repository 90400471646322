.comment-wrapper {
	margin-bottom: 144px;
}

.comment-wrapper .comment-left-block {
	background: #e4e4eb 0% 0% no-repeat padding-box;
	box-shadow: inset 0px 1px 3px #00000029, 0px 1px 3px #00000012;
	border-radius: 10px 0 0 10px;
	padding: 40px 40px 30px 40px;
	height: 100%;
}

.comment-wrapper .comment-right-block {
	background: #292929 0% 0% no-repeat padding-box;
	box-shadow: inset 0px 1px 3px #00000029, 0px 1px 3px #00000012;
	border-radius: 0 10px 10px 0;
	padding: 40px 40px 30px 40px;
	height: 100%;
}

.comment-wrapper .form-control {
	background: #e4e4eb 0% 0% no-repeat padding-box;
	box-shadow: inset 0px 1px 3px #00000029, 0px 1px 3px #00000012;
}

.comment-wrapper .form-control::placeholder {
	font: normal normal medium 12px/14px Roboto;
	letter-spacing: 0px;
	color: #000000;
}

.comment-form-title {
	font: normal normal bold 28px/34px Roboto;
	letter-spacing: 0px;
}

.add-coment-btn {
	color: #fff !important;
	background: #a50e24 0% 0% no-repeat padding-box;
	padding: 12px 25px;
	box-shadow: none !important;
	outline: none !important;
}

.comment-wrapper .social-icon-item {
	position: relative;
	z-index: 1;
	display: inline-block;
	text-align: center;
	line-height: 40px;
	padding: 0;
	margin: 10px;
	overflow: hidden;
	vertical-align: middle;
	cursor: pointer;
	border-radius: 50%;
	-webkit-box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%),
		0 4px 15px 0 rgb(0 0 0 / 15%);
	box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	width: 40px;
	height: 40px;
	background-color: #a50e24;
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-20 {
	margin-bottom: 20px;
}
