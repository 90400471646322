/* main content */

.homeContent {
    background-image: url("../../Assets/Images/HomeBg.png");
    background-position: 0px 0px;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}
/* Left Bar */

.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

.homeContent .leftBar {
    margin-top: 0 !important;
}
.homeContent .leftBar h1 {
    color: #ffffff;
    font-size: 60px;
    font-weight: 900;
    text-transform: uppercase;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.banner-subtitle {
    color: #ffffff;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 16px;
}

@media only screen and (max-width: 990px) {
    .tabs-padding {
        margin-top: 125px !important;
    }
}

.tabs-padding {
    margin-top: 75px;
    color: white;
    background-color: black;
}

.homeContent .leftBar p {
    color: #d6c7c7;
    margin-right: 50px;
    line-height: 35px;
    font-size: 16px;
    font-family: "Raleway", sans-serif;
}
.homeContent .leftBar button {
    text-transform: capitalize;
    color: #a50e24;
    background-color: #ffffff;
    border-radius: 30px;
    padding: 13px 60px;
    padding-right: 90px;
    font-weight: 800;
    font-family: "Raleway", sans-serif;
    border: none;
    font-size: 18px;
    position: relative;
}
.homeContent .leftBar button .moreBtn {
    position: absolute;
    right: -1%;
    top: 0%;
    background-color: #a50e24;
    color: #ffffff;
    font-size: 10px;
    padding: 13px;
    border-radius: 100%;
    height: 53px;
    fill: #ffffff;
}

/* slide Bar */
.slideBar .margin {
    margin-top: 120px;
}
.slick-slider .slick-list {
    padding-bottom: 50px;
}
.slick-slider .slick-dots li {
    background-color: #ffffff;
    height: 10px;
    width: 10px;
}
.slick-slider .slick-dots li button::before {
    font-size: 0px;
}

.slick-slider .slick-dots li.slick-active {
    background-color: brown;
}

@media only screen and (max-width: 990px) {
    .slideBar img {
        height: 500px;
        width: 100%;
        padding-left: 150px;
        padding-right: 150px;
    }
    .slideBar .slick-slider .slick-list {
        padding-bottom: 0px;
    }
    .slideBar .margin {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .slick-slider .slick-dots {
        bottom: 0px;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 760px) {
    .slideBar img {
        height: 500px;
        width: 100%;
        padding-left: 100px;
        padding-right: 100px;
    }
    /* .slideBar .slick-slider .slick-list {
      padding-bottom: 0px;
  }
  .slideBar .margin {
      margin-top: 50px;
      margin-bottom: 50px;
  }
  .slick-slider .slick-dots {
      bottom: 0px;
      margin-bottom: 15px;
  } */
}

@media only screen and (max-width: 600px) {
    .homeContent .leftBar {
        margin-top: 80px;
        text-align: center;
    }
    .homeContent .leftBar h1 {
        font-size: 45px;
    }
    .homeContent .leftBar p {
        margin-right: 0px;
    }
    .slideBar img {
        height: 400px;
        width: 100%;
        padding-left: 50px;
        padding-right: 50px;
    }
    .slideBar .slick-slider .slick-list {
        padding-bottom: 0px;
    }
    .slideBar .margin {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .slick-slider .slick-dots {
        bottom: 0px;
        margin-bottom: 15px;
    }
}

.line-clamp:after {
    color: black;
    bottom: 0;
    content: "...";
    font-weight: bold;
    padding: 0 20px 1px 45px;
    position: absolute;
    right: 0;
}
    
.line-clamp {
    height: 7.5em;
    line-height: 1.4em;
    overflow: hidden;
    position: relative;
}
