@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,800;1,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,600;0,800;1,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,800;1,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,600;0,800;1,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@900&display=swap);
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Raleway", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Raleway", monospace;
}



/* Header Logo */
.fixed-top {
    background-color: #000;
    transition: 0.5s ease;
}
.fixed-top.active {
    background-color: black;
    transition: 0.5s ease;
}

.headerLogo {
    display: flex;
    align-items: center;
}
.headerLogo .leftProp,
.rightProp {
    color: #ffffff;
    text-decoration: none;
    font-size: 27px;
    font-family: "Raleway", sans-serif;
}
.headerLogo .leftProp {
    font-weight: bold;
}
.headerLogo .rightProp {
    font-weight: lighter;
}
.headerLogo:hover {
    text-decoration: none;
}
.headerIcon {
    stroke: #ffffff;
    height: 40px;
}

/* Header nav item */
.navbar .navbar-nav .nav-item {
    margin-left: 35px;
}

.nav-item .main-nav {
    color: #ffffff;
    text-decoration: none;
    font-family: "Raleway", sans-serif;
    font-size: 18px;
}
.nav-item .main-nav-active {
    background-color: #a50e24;
    border-radius: 15px;
    padding: 5px 10px;
    transition: 0.2s ease;
}

@media only screen and (max-width: 600px) {
    .fixed-top {
        background-color: black;
    }
}



.footer {
    background-color: #000000;
    padding: 25px;
}
.footer h3 {
    color: #ffffff;
    font-size: 18px;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.footer .contactUs h5 {
    color: #ffffff;
    font-size: 14px;
    line-height: 15px;
    font-family: "Raleway", sans-serif;
    font-weight: normal;
}

.footer .follow .footerIcon {
    color: #ffffff;
    background-color: #4c4c4c;
    height: 40px;
    width: 50px;
    border-radius: 5px;
    /* font-size: 24px; */
    margin-right: 10px;
    cursor: pointer;
}
.lastDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: black;
}
.lastDiv span {
    font-size: 14px;
    color: white;
    font-family: "Raleway", sans-serif;
    font-weight: normal;
    opacity: 1;
}
.subscribe form {
    display: flex;
    align-items: center;
}
.subscribe input[type="email"] {
    border: none;
    outline: none;
    background-color: #4c4c4c;
    border-color: #4c4c4c;
    color: #ffffff;
    height: 30px;
    padding: 0 25px;
    border-radius: 4px 0 0 4px;
    width: 230px;
    height: 40px;
    font-size: 12px;
    font-family: "Raleway", sans-serif;
}
.subscribe input[type="email"]::-webkit-input-placeholder {
    color: #ffffff;
}
.subscribe input[type="email"]:-ms-input-placeholder {
    color: #ffffff;
}
.subscribe input[type="email"]::placeholder {
    color: #ffffff;
}
.subscribe input[type="submit"] {
    border-radius: 0 4px 4px 0;
    width: 94px;
    height: 40px;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: #a50e24;
    color: #ffffff;
    font-size: 12px;
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    font-weight: bold;
}

@media only screen and (max-width: 770px) {
    .footer .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .footer h3 {
        margin-bottom: 10px;
    }
    .footer .container .follow {
        margin-bottom: 10px;
    }
}

/* main content */

.homeContent {
    background-image: url(/static/media/HomeBg.8c67a9cc.png);
    background-position: 0px 0px;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}
/* Left Bar */

.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

.homeContent .leftBar {
    margin-top: 0 !important;
}
.homeContent .leftBar h1 {
    color: #ffffff;
    font-size: 60px;
    font-weight: 900;
    text-transform: uppercase;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.banner-subtitle {
    color: #ffffff;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 16px;
}

@media only screen and (max-width: 990px) {
    .tabs-padding {
        margin-top: 125px !important;
    }
}

.tabs-padding {
    margin-top: 75px;
    color: white;
    background-color: black;
}

.homeContent .leftBar p {
    color: #d6c7c7;
    margin-right: 50px;
    line-height: 35px;
    font-size: 16px;
    font-family: "Raleway", sans-serif;
}
.homeContent .leftBar button {
    text-transform: capitalize;
    color: #a50e24;
    background-color: #ffffff;
    border-radius: 30px;
    padding: 13px 60px;
    padding-right: 90px;
    font-weight: 800;
    font-family: "Raleway", sans-serif;
    border: none;
    font-size: 18px;
    position: relative;
}
.homeContent .leftBar button .moreBtn {
    position: absolute;
    right: -1%;
    top: 0%;
    background-color: #a50e24;
    color: #ffffff;
    font-size: 10px;
    padding: 13px;
    border-radius: 100%;
    height: 53px;
    fill: #ffffff;
}

/* slide Bar */
.slideBar .margin {
    margin-top: 120px;
}
.slick-slider .slick-list {
    padding-bottom: 50px;
}
.slick-slider .slick-dots li {
    background-color: #ffffff;
    height: 10px;
    width: 10px;
}
.slick-slider .slick-dots li button::before {
    font-size: 0px;
}

.slick-slider .slick-dots li.slick-active {
    background-color: brown;
}

@media only screen and (max-width: 990px) {
    .slideBar img {
        height: 500px;
        width: 100%;
        padding-left: 150px;
        padding-right: 150px;
    }
    .slideBar .slick-slider .slick-list {
        padding-bottom: 0px;
    }
    .slideBar .margin {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .slick-slider .slick-dots {
        bottom: 0px;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 760px) {
    .slideBar img {
        height: 500px;
        width: 100%;
        padding-left: 100px;
        padding-right: 100px;
    }
    /* .slideBar .slick-slider .slick-list {
      padding-bottom: 0px;
  }
  .slideBar .margin {
      margin-top: 50px;
      margin-bottom: 50px;
  }
  .slick-slider .slick-dots {
      bottom: 0px;
      margin-bottom: 15px;
  } */
}

@media only screen and (max-width: 600px) {
    .homeContent .leftBar {
        margin-top: 80px;
        text-align: center;
    }
    .homeContent .leftBar h1 {
        font-size: 45px;
    }
    .homeContent .leftBar p {
        margin-right: 0px;
    }
    .slideBar img {
        height: 400px;
        width: 100%;
        padding-left: 50px;
        padding-right: 50px;
    }
    .slideBar .slick-slider .slick-list {
        padding-bottom: 0px;
    }
    .slideBar .margin {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .slick-slider .slick-dots {
        bottom: 0px;
        margin-bottom: 15px;
    }
}

.line-clamp:after {
    color: black;
    bottom: 0;
    content: "...";
    font-weight: bold;
    padding: 0 20px 1px 45px;
    position: absolute;
    right: 0;
}
    
.line-clamp {
    height: 7.5em;
    line-height: 1.4em;
    overflow: hidden;
    position: relative;
}

.adv-blocksubscribe {
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 1px 4px #00000029;
	border-radius: 10px;
	padding: 8px 7px;
}

.adv-blocksubscribe-wrapper {
	background: transparent url(/static/media/adv-subscribe-bg.8002ca97.png) 0%
		0% no-repeat padding-box;
	background-size: cover;
	padding: 28px 0 38px 0;
}

.adv-blocksubscribe-logowrapper {
	margin-bottom: 137px;
}

.adv-blocksubscribe-logowrapper .adv-logo {
	font-size: 27px;
	font-family: "Raleway", sans-serif;
	text-align: center;
}

.adv-blocksubscribe-contentwrapper {
	color: #ffffff;
	text-align: center;
}

.adv-blocksubscribe-contentwrapper .adv-blocksubscribe-content {
	font: normal normal bold 32px/42px Raleway;
	letter-spacing: 0px;
	color: #ffffff;
	text-transform: uppercase;
}

.adv-blocksubscribe-contentwrapper .adv-subscribe-btn {
	color: #fff;
	background: #a50e24 0% 0% no-repeat padding-box;
	border-radius: 27px;
	padding: 15px 34px;

	font: normal normal normal 18px Raleway;
	box-shadow: none !important;
	outline: none !important;
}

.adv-blockdownload {
	background: #a50e24 0% 0% no-repeat padding-box;
	box-shadow: 0px 1px 4px #00000029;
	border-radius: 10px;
}

.adv-blockdownload-wrapper {
	background: transparent url(/static/media/adv-download-bg.ab59ae05.png) 0% 0%
		no-repeat padding-box;
	background-size: cover;
	padding: 263px 0 89px 0;
	position: relative;
}

.adv-blockdownload-logowrapper {
	position: absolute;
	top: 0;
	left: 0;
	background: #a50e24 0% 0% no-repeat padding-box;
	color: #fff;
}

.adv-blockdownload-logowrapper::before {
	content: "";
}

.adv-blockdownload-logowrapper::after {
	content: "";
}

.adv-blockdownload-logowrapper .adv-blockdownload-logo-content {
	padding: 23px 18px 41px 34px;
	text-align: center;
}

.adv-blockdownload-logowrapper .adv-blockdownload-logo-content h3 {
	font: normal normal bold 40px/42px Raleway;
	margin: 0;
}

.adv-blockdownload-logowrapper .adv-blockdownload-logo-content p {
	font: italic normal 600 14px Raleway;
	margin: 0;
}

.adv-blockdownload-contentwrapper {
	text-align: center;
}

.adv-blockdownload-contentwrapper .adv-download-btn {
	color: #fff;
	background: #a50e24 0% 0% no-repeat padding-box;
	border-radius: 27px;
	padding: 15px 34px;

	font: normal normal normal 18px Raleway;
	box-shadow: none !important;
	outline: none !important;
}

/* News main content */
.newsContent {
    margin-top: 80px;
    margin-bottom: 80px;
}

.newsContent .col-xl-4 {
    padding-left: 0px;
}

/* News Bars */
.newsContent .rightNewsBar {
    background: #ffffff;
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 10px;
}

/* left News Bar */

.leftNewsBar .headerBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}
.leftNewsBar .headerBar h1 {
    color: #000000;
    font-size: 36px;
    font-weight: 900;
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
}
.dropbox-images {
    height: 100% !important;
    width: 100% !important;
}

.leftNewsBar .headerBar select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: #000000;
    font-size: 20px;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    text-transform: capitalize;
    box-shadow: 0px 3px 10px #00000012;
    border-radius: 10px;
    background-color: #ffffff;
    border-color: #e4e3e3;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAA3QAAAN0BcFOiBwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACLSURBVDiN1ZNLCkJBDASrveP7eYo5yAO9r0K7mZFRk0F4bgxkEbqrdpFtjszpEP0zgaRd0vQtJGmStLf7Ahi4AbNtRgvMtevKUurRJMsAXjrYQGlBL7kDawCvNXvCtukL75Kty7YIfhEkknPdEP4QJJIUDgWBJIVTQSAJYdto9AuSCoDta9r5/2d6AIf3+ne4QmeUAAAAAElFTkSuQmCC) no-repeat;
    background-position: right 10px top 50%;
    padding: 10px 30px 10px 10px;
}
.leftNewsBar select option {
    padding: 10px 30px 10px 10px;
}

.leftNewsBar .newsCover {
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 10px 10px 0px 0px;
}
.leftNewsBar .newsCover .newsDiscription {
    padding: 30px;
}
.leftNewsBar .newsCover h1 {
    color: #a50e24;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 22px;
    text-transform: capitalize;
}
.leftNewsBar .newsCover p {
    line-height: 35px;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
}
.leftNewsBar .newsCover .footerBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
}
.leftNewsBar .newsCover .footerBar button {
    text-transform: capitalize;
    color: #a50e24;
    background-color: rgba(255, 255, 255, 1);
    border: none;
    border-radius: 30px;
    padding: 10px 55px;
    padding-right: 70px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    font-weight: 800;
    font-family: "Raleway", sans-serif;
    border-color: #cccccc;
    font-size: 18px;
    position: relative;
}
.leftNewsBar .newsCover .footerBar button .moreBtn {
    position: absolute;
    right: -1%;
    top: 0%;
    background-color: #a50e24;
    color: #ffffff;
    font-size: 10px;
    padding: 13px;
    border-radius: 100%;
    height: 48px;
    fill: #ffffff;
}
.leftNewsBar .newsCover .footerBar .newsDate {
    color: #a50e24;
    font-size: 14px;
    font-weight: 600;
}

/* Right News Bar  */

.rightNewsBar .tab-link {
    width: 100%;
}

.newsInline .newsTabs .tab-link {
    color: #000;
    text-transform: capitalize;
    font-size: 18px;
    box-shadow: 1px 1px 1px #d0d4e159;
    font-family: "Raleway", sans-serif;
    margin: 0px;
    font-weight: 600;
    padding: 15px;
    transition: 0s;
}

.newsInline .newsTabs .fEHnjU {
    background-color: #ffffff;
    color: #a50e24;
    border-color: #a50e24;
}
.newsInline .kZvMDR {
    margin-bottom: 0px;
}

.newsInline .newsItem {
    display: flex;
    padding: 15px;
    box-shadow: 0px 1px 4px #00000029;
}

.newsInline .newsDecrip {
    width: 100%;
}

.newsItem .newsDecrip h6 {
    font-size: 12px;
    color: #000;
    text-transform: capitalize;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    padding: 5px;
}
.newsDecrip .moreDiscrip {
    display: flex;
    justify-content: space-between;
    padding: 5px;
}
.newsDecrip .moreDiscrip span,
.newsDecrip .moreDiscrip a {
    color: #a50e24;
    font-size: 10px;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    cursor: pointer;
}

/* Pagination */

.paginationBttns {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-padding-start: 0px !important;
            padding-inline-start: 0px !important;
    height: 40px;
    margin-top: 15px;
}
.paginationBttns a {
    padding: 5px 8px;
    margin: 2px;
    border-radius: 10px;
    border: 2px solid #000;
    color: #000;
    cursor: pointer;
}
.paginationBttns a:hover {
    color: #fff !important;
    background-color: #a50e24;
    text-decoration: none !important;
    border-color: #a50e24;
    /* transition: 0.1s ease-in-out; */
}
.paginationAcitive a {
    color: #fff !important;
    background-color: #a50e24;
    border-color: #a50e24;
    outline: none;
}
@media only screen and (max-width: 990px) {
    .leftNewsBar {
        margin-bottom: 25px;
    }
}
@media only screen and (max-width: 600px) {
    .newsContent {
        margin-top: 25px;
    }

    .newsCover img {
        height: 230px;
        width: 100%;
    }
    .leftNewsBar {
        box-shadow: 0 0 50px #ccc;
        border-radius: 30px 30px 0px 0px;
    }
    .rightNewsBar .cvjUth {
        box-shadow: 0 0 50px #ccc;
    }

    .leftNewsBar .headerBar h1 {
        padding: 15px 15px;
        font-size: 30px;
    }
    .leftNewsBar .headerBar select {
        padding: 0px 30px 5px 10px;
    }
    .leftNewsBar .newsCover .footerBar {
        flex-direction: column-reverse;
        padding: 0px;
        padding-bottom: 20px;
    }
    .leftNewsBar .newsCover .footerBar button {
        padding: 5px 25px;
        padding-right: 50px;
        margin-bottom: 10px;
    }
    .leftNewsBar .newsCover .footerBar button .moreBtn {
        padding: 8px;
        height: 37px;
    }

    .newsContent .container .row .col-xl-4 {
        padding: 0px;
        margin-top: 15px;
    }
}

/* main content */

.homeContent {
    background-image: url(/static/media/HomeBg.8c67a9cc.png);
    background-position: 0px 0px;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}
/* Left Bar */

.homeContent .leftBar {
    margin-top: 100px;
}
.homeContent .leftBar h1 {
    color: #ffffff;
    font-size: 60px;
    font-weight: 900;
    text-transform: uppercase;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.banner-subtitle {
    color: #ffffff;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 16px;
}

.homeContent .leftBar p {
    color: #d6c7c7;
    margin-right: 50px;
    line-height: 35px;
    font-size: 16px;
    font-family: "Raleway", sans-serif;
}
.homeContent .leftBar button {
    text-transform: capitalize;
    color: #a50e24;
    background-color: #ffffff;
    border-radius: 30px;
    padding: 13px 60px;
    padding-right: 90px;
    font-weight: 800;
    font-family: "Raleway", sans-serif;
    border: none;
    font-size: 18px;
    position: relative;
}
.homeContent .leftBar button .moreBtn {
    position: absolute;
    right: -1%;
    top: 0%;
    background-color: #a50e24;
    color: #ffffff;
    font-size: 10px;
    padding: 13px;
    border-radius: 100%;
    height: 53px;
    fill: #ffffff;
}

/* slide Bar */
.slideBar .margin {
    margin-top: 120px;
}
.slick-slider .slick-list {
    padding-bottom: 50px;
}
.slick-slider .slick-dots li {
    background-color: #ffffff;
    height: 10px;
    width: 10px;
}
.slick-slider .slick-dots li button::before {
    font-size: 0px;
}

.slick-slider .slick-dots li.slick-active {
    background-color: brown;
}

@media only screen and (max-width: 990px) {
    .slideBar img {
        height: 500px;
        width: 100%;
        padding-left: 150px;
        padding-right: 150px;
    }
    .slideBar .slick-slider .slick-list {
        padding-bottom: 0px;
    }
    .slideBar .margin {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .slick-slider .slick-dots {
        bottom: 0px;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 760px) {
    .slideBar img {
        height: 500px;
        width: 100%;
        padding-left: 100px;
        padding-right: 100px;
    }
    /* .slideBar .slick-slider .slick-list {
      padding-bottom: 0px;
  }
  .slideBar .margin {
      margin-top: 50px;
      margin-bottom: 50px;
  }
  .slick-slider .slick-dots {
      bottom: 0px;
      margin-bottom: 15px;
  } */
}

@media only screen and (max-width: 600px) {
    .homeContent .leftBar {
        margin-top: 80px;
        text-align: center;
    }
    .homeContent .leftBar h1 {
        font-size: 45px;
    }
    .homeContent .leftBar p {
        margin-right: 0px;
    }
    .slideBar img {
        height: 400px;
        width: 100%;
        padding-left: 50px;
        padding-right: 50px;
    }
    .slideBar .slick-slider .slick-list {
        padding-bottom: 0px;
    }
    .slideBar .margin {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .slick-slider .slick-dots {
        bottom: 0px;
        margin-bottom: 15px;
    }
}

.horizontal-adv {
	font-family: "Roboto", sans-serif;
	background: transparent url(/static/media/horizontal-adv-bg.b1489057.svg) 0%
		0% no-repeat padding-box;
	padding: 30px 0;
	margin-bottom: 50px;
	position: relative;
}

.horizontal-adv-layer {
	background: transparent
		linear-gradient(127deg, #000000 0%, #5b5b5b 73%, #7d7d7d 100%) 0% 0%
		no-repeat padding-box;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.6;
}

.horizontal-adv .adv-subtitle {
	font: normal normal normal 49px/59px Roboto;
	letter-spacing: 0px;
	text-transform: uppercase;
	opacity: 1;
}

.horizontal-adv .adv-title {
	font: normal normal bold 70px/85px Roboto;
	letter-spacing: 0px;
	text-transform: uppercase;
	opacity: 1;
}

.horizontal-adv .adv-sale {
	font: normal normal normal 90px/95px Roboto;
	letter-spacing: 0px;
	text-transform: uppercase;
	opacity: 1;
}

.horizontal-adv .adv-btn {
	color: #fff;
	background: #a50e24 0% 0% no-repeat padding-box;
	padding: 15px 52px;
	border-radius: 40px;
	font: normal normal normal 34px Raleway;
	box-shadow: none !important;
	outline: none !important;
}

.text-firebrick {
	color: #a50e24;
}

.comment-wrapper {
	margin-bottom: 144px;
}

.comment-wrapper .comment-left-block {
	background: #e4e4eb 0% 0% no-repeat padding-box;
	box-shadow: inset 0px 1px 3px #00000029, 0px 1px 3px #00000012;
	border-radius: 10px 0 0 10px;
	padding: 40px 40px 30px 40px;
	height: 100%;
}

.comment-wrapper .comment-right-block {
	background: #292929 0% 0% no-repeat padding-box;
	box-shadow: inset 0px 1px 3px #00000029, 0px 1px 3px #00000012;
	border-radius: 0 10px 10px 0;
	padding: 40px 40px 30px 40px;
	height: 100%;
}

.comment-wrapper .form-control {
	background: #e4e4eb 0% 0% no-repeat padding-box;
	box-shadow: inset 0px 1px 3px #00000029, 0px 1px 3px #00000012;
}

.comment-wrapper .form-control::-webkit-input-placeholder {
	font: normal normal medium 12px/14px Roboto;
	letter-spacing: 0px;
	color: #000000;
}

.comment-wrapper .form-control:-ms-input-placeholder {
	font: normal normal medium 12px/14px Roboto;
	letter-spacing: 0px;
	color: #000000;
}

.comment-wrapper .form-control::placeholder {
	font: normal normal medium 12px/14px Roboto;
	letter-spacing: 0px;
	color: #000000;
}

.comment-form-title {
	font: normal normal bold 28px/34px Roboto;
	letter-spacing: 0px;
}

.add-coment-btn {
	color: #fff !important;
	background: #a50e24 0% 0% no-repeat padding-box;
	padding: 12px 25px;
	box-shadow: none !important;
	outline: none !important;
}

.comment-wrapper .social-icon-item {
	position: relative;
	z-index: 1;
	display: inline-block;
	text-align: center;
	line-height: 40px;
	padding: 0;
	margin: 10px;
	overflow: hidden;
	vertical-align: middle;
	cursor: pointer;
	border-radius: 50%;
	box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
	transition: all 0.2s ease-in-out;
	width: 40px;
	height: 40px;
	background-color: #a50e24;
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-20 {
	margin-bottom: 20px;
}

.secondContent {
    background-image: url(/static/media/HomeBg.8c67a9cc.png);
    background-position: 0px 0px;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    overflow: hidden;
}
.secondBar .contentTo {
    margin-top: 220px;

    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}
.secondBar .contentTo h1 {
    color: #fff;
    font-size: 36px;
    font-weight: 500;
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    text-align: center;
}
.contentTo h1 {
    text-align: center;
}
.secondCover p {
    color: #fff;
    /* margin-bottom: 70px; */
    text-align: left;
}

.newsDetailDiscription {
    color: #fff;
    margin-bottom: 70px;
    text-align: left;
}

.leftBar .contentTo select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: #fff;
    font-size: 20px;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    text-transform: capitalize;
    box-shadow: 0px 3px 10px #00000012;
    border-radius: 10px;
    background-color: #ffffff;
    border-color: #e4e3e3;
    background-position: right 10px top 50%;
    padding: 10px 30px 10px 10px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAA3QAAAN0BcFOiBwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACLSURBVDiN1ZNLCkJBDASrveP7eYo5yAO9r0K7mZFRk0F4bgxkEbqrdpFtjszpEP0zgaRd0vQtJGmStLf7Ahi4AbNtRgvMtevKUurRJMsAXjrYQGlBL7kDawCvNXvCtukL75Kty7YIfhEkknPdEP4QJJIUDgWBJIVTQSAJYdto9AuSCoDta9r5/2d6AIf3+ne4QmeUAAAAAElFTkSuQmCC) no-repeat;
}
.secondTitle {
    text-align: center;
    margin: 70px 0px 80px;
}

@media only screen and (max-width: 990px) {
    .detailImg {
        max-width: 100%;
        height: auto;
    }
}

.dropbox-images {
    height: 100% !important;
    width: 100% !important;
}


@media (max-width: 990px) {
    .iframer {
        width: 1000px !important;
        height: 500px !important;
        transform: scale(0.5);
        margin-left: -100px !important;
    }
}

.nameRow {
    list-style-type: none;
    display: flex;
    padding: 0;
}
.nameRow:active {
    border: none;
}
.discriptionRow {
    list-style-type: none;
    padding: 0;
}
.icoli {
    margin-right: 15px;
}
#textDesc {
    border-radius: 4px;
    margin-left: 56px;
}
#userIcon {
    border-radius: 4px;
}
#userRow {
    border-radius: 4px;
    padding: 7px 25px;
    border: 1px solid #fff;
}
#userRow:focus {
    border: 1px solid red;
}
#comRow {
    width: 444px;
    border-radius: 4px;
    padding: 6px 5px 0px 5px;
    border: 1px solid #fff;
    background: #fff;
}

.comRow {
    /* width: 444px; */
    border-radius: 4px;
    padding: 6px 5px 0px 5px;
    border: 1px solid #fff;
    background: #fff;
}

.commentBtn {
    text-transform: capitalize;
    color: #a50e24;
    background-color: #ffffff;
    border-radius: 30px;
    padding: 8px 30px;
    margin-left: 56px;
    margin-top: 9px;
    font-weight: 800;
    font-family: "Raleway", sans-serif;
    border: none;
    font-size: 16px;
    position: relative;
}
.commentRow {
    display: flex;
}
.nameComm {
    text-align: center;
    color: red;
    margin-right: 10px;
}
#iconRow {
    margin-left: 41px;
    margin-bottom: 15px;
}
#mainRow {
    margin: 0;
    padding: 0;
}
#commR {
    background-color: #fff;
    border-radius: 4px;
}
.commentP {
    margin: 0;
    padding: 0;
}


